body {
  background-color: #A9A1BA;
  color: #dddddd;
  font-family: arial, helevtica, sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.body-grid-cell {
  padding: 10px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 0px 20px 0px 20px;
}

#title-cell {
  font-family: 'Fascinate', cursive;
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  background-image: url("/public/starfield-bg.png");
}

#footer-cell {
  color: #61696E;
}

.nav-item {
  text-align: left;
}
